@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply bg-dark text-white font-hind;
}

html,
body {
    height: 100%;
}

.font-hind {
    font-family: var(--font-hind);
}

.font-noland {
    font-family: var(--font-noland);
    letter-spacing: 2.5px;
}

@layer base {
    :root {
        --color-primary: #ffa100;
        --color-footer: #f9fafb;
    }
}

/* Hide scrollbar */
@layer utilities {
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}

@layer components {
    .btn {
        @apply text-base uppercase font-bold px-30 py-4 rounded-lg text-center h-52 min-w-180 flex items-center justify-center md:h-42 md:min-w-140 md:px-20;
    }

    .btn-primary {
        @apply bg-primary text-dark;
    }

    .btn-secondary {
        @apply text-white border border-white;
    }

    .btn-gray {
        @apply text-dark bg-lightGray;
    }
}
